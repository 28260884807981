import './App.css';
import { useState } from "react"
import axios, { AxiosResponse } from 'axios';

import { FileUpload } from "./components/FileUpload";
import { TextArea } from "./components/TextArea";
import { ButtonStyled } from "./components/Button";

function App() {

  const emptyFile: File = {} as File

  const [visibility, setVisibility] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [file, setFile] = useState<File>(emptyFile);
  const [fileName, setFileName] = useState<string>("No file chosen");

  const base_url = process.env.REACT_APP_BACKEND_BASE_URL || "http://localhost:8080";
  
  const aws_bill_csv_path = "/aws-bill-csv";
  const pdf_to_text_path = "/pdf-to-text";

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
      setVisibility(true)
      if (!event.target.files) {
        return;
      }
      const file = event.target.files?.[0];
      const { name } = file;
      setFile(file);
      setFileName(name);

      const bodyFormData = new FormData()
      bodyFormData.append("pdf_file", file)
      const headers = {
        headers: {
          "Content-Type" : "multipart/form-data",
        }
      }

      axios.post(base_url + pdf_to_text_path, bodyFormData, headers).then((response: AxiosResponse) => {
          setText(response.data);
          setVisibility(false)
      })
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {

      const bodyFormData = new FormData()
      bodyFormData.append("text_data", text)
      bodyFormData.append("pdf_file", file)

      axios.post(base_url + aws_bill_csv_path, bodyFormData).then((response: AxiosResponse) => {

          const url = window.URL.createObjectURL(new Blob([response.data])) 
          const link = document.createElement('a')
          link.href = url
          const fileName = `awsbill.csv`;
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
      })
  };

  return (
    <div className="App">
        <div style={{ paddingTop: "20px"}}/>
        <FileUpload 
          text = { fileName }
          loadingVisiblity = { visibility }
          style = {{ 
            width: "484px",
            padding: "8px 8px",
            // fontSize: "0.875rem",
            borderRadius: "8px"
          }}
          handleUpload={handleUpload}
        />
        <TextArea 
          value={text} 
          placeholder="After copying and pasting the contents of your AWS bill PDF into the text area, press the button below to convert it to CSV."
          style={{ 
            height: "500px", 
            width: "484px",
            padding: "8px 8px",
            // fontSize: "0.875rem",
            borderRadius: "8px"
          }}
          handleChange={event => setText(event.target.value)} 
        />
        <ButtonStyled
          buttonText="Convert AWS Bill to CSV"
          variant="contained" 
          style={{ width: "500px" }}
          handleClick={handleClick}
        />
    </div>
  );
}

export default App;
