import React from "react"
import { Button,CircularProgress } from "@mui/material"

type FileUploadProps = {
    style: {}
    text: string
    loadingVisiblity: boolean
    handleUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
} 

export const FileUpload = (props: FileUploadProps) => {

    return (
        <div
            style={{
                display:"inline-block", 
                textAlign:"center"
            }}>
            <div
                style={{ 
                    display: "flex",
                    textAlign:"left",
                    width: "500px",
                    fontSize: "0.875rem",
                    // border:"1px solid black",
                    }}
                >
                <Button
                    variant="contained"
                    component="label"
                    sx={{ 
                        marginRight: "1rem",
                        marginBottom: "5px"
                    }}
                >
                Upload File
                <input 
                    type="file" 
                    accept="application/pdf"
                    style={props.style} 
                    onChange={props.handleUpload}
                    hidden
                />
                </Button>
                <div>
                    <p>{props.text}</p>
                </div>
                <div>
                { 
                    props.loadingVisiblity && <CircularProgress 
                        sx={{ 
                            marginLeft: "1rem",
                            size: "10rem",
                        }}
                    /> 
                }
                </div>
            </div>
        </div>
    )
}