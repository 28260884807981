import React from "react"
import { Button } from "@mui/material"

type ButtonProps = {
    variant: "text" | "contained" | "outlined"
    buttonText: String
    style: {}
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
} 

export const ButtonStyled = (props: ButtonProps) => {
    return (
        <Button
            variant={props.variant}
            style={props.style}  
            onClick={event => props.handleClick(event)}>{props.buttonText}
        </Button>
    )
}